<template>
  <div class="flex min-h-screen flex-col">
    <app-header />
    <div class="flex-grow bg-background pb-12">
      <slot />
    </div>
    <!--<app-footer/>-->
    <cookie-statement />
    <search-sector-code />
    <review ref="reviewModal" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$root.$reviewModal = this.$refs.reviewModal;
  },
};
</script>
